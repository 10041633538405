<template>
  <div
    class="m-brand-logo"
    :class="{'m-brand-logo--no-logo': !urlLogoBrand}"
  >
    <div
      class="m-brand-logo__logo-wrapper"
      v-if="urlLogoBrand"
    >
      <object
        class="m-brand-logo--logo"
        :type="typeImage"
        :data="urlLogoBrand"
      />
    </div>
    <div
      v-if="promotionBanners.length"
      class="m-brand-logo__banner-carousel"
    >
      <MBannerCarousel
        :key="promotionBanners.length"
        :banners="promotionBanners"
        :is-responsive="true"
      />
    </div>
  </div>
</template>
<script>
import MBannerCarousel from 'theme/components/molecules/m-banner-carousel';
import { getImageByFetch } from 'theme/helpers';

export default {
  name: 'MBrandLogo',
  components: {
    MBannerCarousel
  },
  data () {
    return {
      typeImage: 'image/svg+xml',
      urlLogoBrand: undefined
    }
  },
  props: {
    brandId: {
      type: String,
      default: ''
    },
    promotionBanners: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
    this.$nextTick(async () => {
      const res = await getImageByFetch(this.brandId)

      if (res.status === 200) {
        this.getUrlAndType(res)
      }
    })
  },
  methods: {
    getUrlAndType (res) {
      this.typeImage = res.headers.get('Content-Type')
      this.urlLogoBrand = res.url
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

$slider-desktop-height: px2rem(150);
$slider-mobile-height: px2rem(125);

.m-brand-logo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: var(--spacer-40);
  grid-column-gap: var(--spacer-10);
  padding: 0 var(--spacer-10);

  &__logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
    box-sizing: border-box;
    height: $slider-mobile-height;
    min-width: 200px;
    background: var(--white);
    border: 1px solid var( --gray3);

    @media (max-width: $tablet-max) {
      margin-bottom: px2rem(10);
    }

    @include for-desktop {
      max-width: 230px;
      height: $slider-desktop-height;
      flex-wrap: nowrap;
    }

    @include for-tablet {
      height: $slider-mobile-height;
    }
  }

  &--no-logo {
    .m-brand-logo__banner-carousel {
      flex-grow: initial;
    }
  }

  &__banner-carousel {
    display: flex;
    flex-grow: 1;
    margin-top: 0;
    width: 100%;

    @media (min-width: $tablet-min) {
      width: calc(100% - 225px);
      margin-left: auto;
    }

    ::v-deep {
      .product-carousel {
        .glide__track {
          padding-top: 0;
        }

        .sf-carousel__wrapper {
          margin-top: 0;
        }

        .sf-banner.sf-banner-plp {
          min-height: $slider-desktop-height;
          background-position-x: right;

          @include for-desktop {
            min-height: $slider-desktop-height;
          }

          @include for-tablet {
            min-height: $slider-mobile-height;
          }
        }
      }
    }
  }
}
</style>
